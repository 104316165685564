import Header from '../helpers/header/header'
import { useState, useEffect } from 'react'
import { url } from '../helpers/url';
import axios from 'axios';
import Pagination from '../admin-component/pagination'
import { Link } from 'react-router-dom';
import { todayIs } from '../helpers/functions'
function Recent() {
  const [load, setLoad] = useState(false)
  const [row, setRow] = useState([])
  const [count, setCount] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [oldCount, setOldCount] = useState([])
  useEffect(() => {
    axios.get(url + `/post/shame?page=0`)
      .then(({ data }) => {
        if (data.success) {
          setRow(data.rows)
          setOldRow(data.rows)
          setCount(data.count)
          setOldCount(data.count)
        }
        setLoad(true)
      })
  }, [])

  function getPaginationId(id) {

    setLoad(false)
    const page = id > 0 ? id * 20 : 0
    axios.get(`${url}/post/shame?page=${page}&mode=view`)
      .then(({ data }) => {
        setRow(data.rows);
        setOldRow(data.rows)
        setCount(data.count);
        setOldCount(data.count);
        setLoad(true);
      })
  }

  return (
    <>
      <Header>
        <div className="col-lg-9 body_col glory">
          <h3 className='title'>Wall of Shame</h3>
          {/* <h4 className='title glory_sub'>Top Market Calls by Members</h4> */}
          {(load && row.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && row.length > 0) && <>

            <div className="table-responsive">
              <table class="front_table table table-hover table-striped left_table table-dark">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Member</th>
                    <th>Market Call</th>
                    <th>Percentage Loss</th>
                    <th>View Post</th>
                  </tr>
                </thead>
                <tbody>
                  {row.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{todayIs(item.createdAt)}</td>
                        <td>{item['registers.username']}</td>
                        <td>{item.shame_text}</td>
                        <td className={Math.sign(item.shame_gain) === -1 ? 'gain_percentage negetive' : 'gain_percentage'}>{item.shame_gain}%</td>
                        <td><Link target="_blank" className="btn glory_btn" to={"/post/" + item.id}>View Post</Link></td>
                      </tr>
                    )
                  })}


                </tbody>
              </table>
            </div>

          </>
          }
          {count.length > 0 && <Pagination row={row.length} count={count} clickId={getPaginationId} />}
        </div>
      </Header>

    </>
  );
}

export default Recent;