import Layout from '../../../admin-component/layout'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from "../../../helpers/url";
import Pagination from '../../../admin-component/pagination';

function ViewUsers() {
  const [row, setRow] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [count, setCount] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [load, setLoad] = useState(false)
  useEffect(() => {
    axios.get(`${url}/admin/post?page=0&mode=view`).then(({ data }) => {
      if (data.success) {
        setRow(data.rows)
        setOldRow(data.rows)
        setCount(data.count)
        setOldCount(data.count)
        setLoad(true);
      }
    })
  }, [])

  const postHandler = (id) => {
    axios.post(`${url}/admin/post/`, { mode: 'delete', id: id }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id !== id)
        setRow(filter)
      }
    })
  }
  function getPaginationId(id) {
    setLoad(false)
    const page = id > 0 ? id * 20 : 0
    axios.get(`${url}/admin/post?page=${page}&mode=view`)
      .then(({ data }) => {
        setRow(data.rows);
        setOldRow(data.rows)
        setCount(data.count);
        setOldCount(data.count);
        setLoad(true);
      })
  }


  const chnageHandler = (e, id, arrayId) => {
    const status = e.target.value
    axios.post(`${url}/admin/post/`, { mode: 'edit', id: id, status: status }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id === id)
        let update = [...row]
        update[arrayId].status = status
        setRow(update)
      }
    })
  }

  const [timer, setTimer] = useState(null);

  const changeDelay = (change) => {
    if (change.length) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          axios.get(`${url}/admin/search?search=${change}&type=post`)
            .then(({ data }) => {
              setRow(data.rows)
              setCount(data.count);
            });
        }, 1000)
      );
    }
    if (change.length === 0) {
      setRow(oldRow)
      setCount(oldCount)
    }
  }


  const readMoreHandler = (e, arrayId, postId) => {
    const data = new FormData()
    const copyRow = [...row]
    const read_more = !copyRow[arrayId].read_more
    copyRow[arrayId].read_more = read_more
    data.append("mode", "read_more")
    data.append("read_more", read_more)
    data.append("id", postId)
    axios.post(`${url}/admin/post/`, data).then(({ data }) => {
      if (data.success) {
        setRow(copyRow)
      }
    })
  }

  const loginHandler = (e, arrayId, postId) => {
    const data = new FormData()
    const copyRow = [...row]
    const login = !copyRow[arrayId].login
    copyRow[arrayId].login = login
    data.append("login", login)
    data.append("id", postId)
    data.append("mode", "login")
    axios.post(`${url}/admin/post/`, data).then(({ data }) => {
      if (data.success) {
        setRow(copyRow)
      }
    })
  }

  return (
    <Layout>

      <div className="admin_table">
        <div className="admin_body_header">
          <div className="left_header">
            <h3>Post List</h3>
          </div>
          <div className="right_header">
            <input onChange={(e) => changeDelay(e.target.value)} type="text" name="" id="" placeholder='Search post' />
          </div>

        </div>

        {
          load && row.length === 0 && <div className="alert alert-danger">
            No user is found
          </div>
        }

        <table class="table table-hover table-striped left_table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>View User</th>
              <th>View Post</th>
              <th>Glory Page</th>
              <th>Glory</th>
              <th>Shame Page</th>
              <th>Shame</th>
              {/* <th>Traded</th>
              <th>Trade</th> */}
              <th>Read More</th>
              <th>Public</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {row.map((item, index) => {
              const postType = item.type
              if (item.type === "Polls" && item.content) {
                item.title = JSON.parse(item.content).title
              }
              return (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td>{item.type}</td>
                  <td><Link className='btn btn-warning' target={"_blank"} to={"/profile/" + item.userId}>View User</Link></td>
                  <td><Link className='btn btn-success' target={"_blank"} to={"/post/" + item.id}>View Post</Link></td>
                  <td>{item.feature ? 'Yes' : 'No'}</td>
                  <td><Link className='btn btn-danger' target={"_blank"} to={"/admin/post/" + item.id}>Glory</Link></td>
                  <td>{item.shame ? 'Yes' : 'No'}</td>
                  <td><Link className='btn btn-danger' target={"_blank"} to={"/admin/post/shame/" + item.id}>Shame</Link></td>
                  {/* <td>{item.trade ? 'Yes' : 'No'}</td> */}
                  {/* <td><Link className='btn btn-danger' target={"_blank"} to={"/admin/trade/" + item.id}>Trade</Link></td> */}
                  <td><input name='read_more' onChange={(e) => readMoreHandler(e, index, item.id,)} type="checkbox" checked={item.read_more} value={item.read_more} /></td>
                  <td><input name='read_more' onChange={(e) => loginHandler(e, index, item.id,)} type="checkbox" checked={item.login} value={item.login} /></td>

                  <td className='cursor' onClick={() => postHandler(item.id)}><p
                    className='btn btn-dark'>Delete</p></td>

                </tr>
              )
            })}

            {count.length > 0 && <Pagination row={row.length} count={count} clickId={getPaginationId} />}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewUsers;